<template>
    <div class="card px-4">
        <h5 class="mb-5 font-medium">Manajemen Konten</h5>
        <div class="grid align-items-center mb-5">
            <div class="col-12 sm:col-6 lg:mb-0 pb-0">
                <form class="grid formgrid" @submit.prevent="doSearch()">
                    <div class="col-9 mb-3 lg:mb-0">
                        <span class="p-input-icon-right w-full">
                            <InputText type="text" placeholder="Cari disini" class="w-full" v-model="keyword" />
                            <i class="pi pi-search" />
                        </span>
                    </div>
                    <div class="col-2 mb-3 lg:mb-0">
                        <Button label="" type="submit" icon="pi pi-filter-fill" class="btn-white px-2">
                            <icon-enter></icon-enter>
                        </Button>
                    </div>
                </form>
            </div>
            <div class="col-12 mb-2 sm:col-6 text-right lg:mb-0 py-0">
                <Button label="Tambah Konten" type="submit" icon="pi pi-plus" class="btn-primary" @click="add()" />
            </div>
        </div>

        <table-list :data="data" :columns="columns"
            :loading="loading"
            :offset="offset"
            :limit="limit"
            :actions="['edit', 'delete']"
            @callbackPaginate="currentPage"
            @edit="onEdit"
            @delete="deleteConfirmation">
        </table-list>
    </div>

    <Dialog header="Confirmation" v-model:visible="delete_confirmation" :style="{width: '350px'}" :modal="true">
        <div class="confirmation-content">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span>Are you sure you want to proceed?</span>
        </div>
        <template #footer>
            <Button label="No" icon="pi pi-times" @click="delete_confirmation = false" class="p-button-text" :disabled="is_deleting" />
            <Button class="p-button-text p-button-danger"
                @click="deleteItem(storage)"
                :disabled="is_deleting">
                <span v-if="!is_deleting">Yes</span>
                <i class="pi pi-spin pi-spinner" v-else></i>
            </Button>
        </template>
    </Dialog>
</template>

<script>
import TableList from '@/components/Table'
import IconEnter from '@/components/IconEnter'

export default {
    components: {
        TableList, IconEnter,
    },
    data() {
        return {
            moment: require('moment-timezone'),
            loading: false,
            limit: 10,
            offset: 0,
            keyword: '',
            show_form: false,
            form: {
                icon: [],
                type: '',
                status: false,
            },
            data: [],
            all_data: [],
            columns: [
                { field: 'id', header: 'ID', sortable: true },
                { field: 'title', header: 'Judul', sortable: true, position: 'left' },
                { field: 'type', header: 'Tipe', sortable: true, position: 'left' },
                { 
                    field: 'is_publish',
                    header: 'Publish',
                    sortable: true,
                    process: (data) => this.publish[data.toString()],
                },
            ],
            status: {
                0: { label: 'Inactive', type: 'tag', class: 'mr-2 py-1 text-10 border-round-lg bg-linear-red', icon: 'pi pi-times' },
                1: { label: 'Active', type: 'tag', class: 'mr-2 py-1 text-10 border-round-lg bg-linear-green', icon: 'pi pi-check' },
            },
            publish: {
                'false': { label: 'Not Published', type: 'tag', class: 'mr-2 py-1 text-10 border-round-lg bg-linear-red', icon: 'pi pi-times' },
                'true': { label: 'Published', type: 'tag', class: 'mr-2 py-1 text-10 border-round-lg bg-linear-green', icon: 'pi pi-check' },
            },
            products: [
                {name: 'Pulsa'},
                {name: 'E-Wallet'},
            ],
            delete_confirmation: false,
            storage: null,
            is_deleting: false,
        }
    },
    mounted () {
        this.getData();
    },
    methods: {
        add() {
            this.$router.push({name: 'add-content'})
        },
        onEdit(data) {
            this.$router.push({name: 'edit-content', params: {id: data.id}})
        },
        getData() {
            this.loading = true
            this.$http
                .get(`${process.env.VUE_APP_API_URL}/contents`)
                .then(response => {
                    this.loading = false
                    this.data = response.data.data.contents
                    this.all_data = this.data
                })
        },
        currentPage(event) {
            this.limit = event.rows
            this.offset = event.page * this.limit
        },
        doSearch() {
            let keyword = this.keyword.toLowerCase()

            if (keyword === 'published' || keyword === 'publish') {
                this.data = this.all_data.filter(item => item.is_publish)
                return
            } else if (keyword === 'not published') {
                this.data = this.all_data.filter(item => !item.is_publish)
                return
            }

            this.data = this.all_data.filter(
                (item) =>
                    item.title.toLowerCase().includes(keyword) ||
                    item.detail.toLowerCase().includes(keyword) ||
                    item.type.toLowerCase().includes(keyword)
            );
        },
        deleteConfirmation(data) {
            this.delete_confirmation = true
            this.storage = data
        },
        deleteItem(data) {
            this.is_deleting = true
            let form = data
            form.status = 0

            this.$http.put(`${process.env.VUE_APP_API_URL}/contents/${data.id}`, form)
                .then(response => {
                    this.is_deleting = false

                    if (response.data.code !== 200) {
                        this.$toast.add({
                            severity:'error', 
                            summary: 'Error!', 
                            detail: response.data.message, 
                            life: 5000,
                        })
                        return
                    }

                    let index = this.data.findIndex(obj => obj.id === data.id)
                    this.data.splice(index, 1)
                    this.delete_confirmation = false

                    this.$toast.add({
                        severity:'success', 
                        summary: 'Success!', 
                        detail: response.data.message, 
                        life: 5000,
                    })
                })
        },
    },
}
</script>

<style lang="scss" scoped></style>
